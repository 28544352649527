@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import "./colors";

// change the theme
$theme-colors: (
    "primary": $color-positive,
    "danger": $color-negative--20,
    "secondary": white,
    "link": white
);

$form-check-input-border: 2px solid $color-positive;
$form-check-input-checked-bg-color: $color-positive;
$form-check-input-border-radius: 4px;
$form-check-input-width: 1.25em;

$input-focus-border-color: $color-positive-20;
$link-color: #00509A;
$link-hover-color: $color-positive-20;

$li-event-background: #F1F2F5;
$li-event-dark: #1D1D1F;

$light-border-color: #ced4da;

@import "./react-bootstrap-range-slider.scss";
@import '~bootstrap/scss/bootstrap.scss';

#root,
body,
html {
    height: 100%;
}

.icon {
    font-family: "Material Icons", serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
}

* {
    box-sizing: border-box;
}

body {
    font-family: "Roboto", "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    position: relative;
}

header {
    display: block;
    width: 100%;
    padding: 1.5em 0;
    border-bottom: 1px solid lightgray;
    background: white;
}

input,
select,
textarea,
.rbt-input-multi {
    transition: none !important;
}

input:focus,
select:focus,
.btn:focus,
.rbt-input-multi.focus,
textarea {
    box-shadow: none !important;
}

.rbt-input-multi.focus {
    border-color: $input-focus-border-color !important;
}

.input-group {

    &>input.form-control:focus,
    &>.query-dropdown>.form-select:focus {
        border-color: $light-border-color;
    }
}

select,
input,
textarea {
    font-weight: 500 !important;

    &::placeholder {
        font-weight: normal !important;
    }
}

.page-item.active .page-link {
    background-color: $color-positive !important;
    border-color: $color-positive !important;
}

.invalid-feedback {
    color: $color-negative--20;
    font-size: 12px;
    line-height: 16px;
}

.alt-checkbox {
    .form-check-input {
        &:checked {
            background-color: $color-positive--40;
        }
    }
}

.btn {
    color: white;
    border-radius: 2px;
    font: normal normal medium 15px/20px Roboto;
    font-weight: 500;
}

.btn-secondary {
    color: $color-positive;
    border: 2px solid $color-positive;

    &:hover,
    &:focus {
        color: $color-positive-20;
        border: 2px solid $color-positive-20;
    }
}

.bg-secondary {
    background-color: $color-positive !important;
}

.btn-danger {

    &:hover,
    &:focus {
        color: white;
    }
}

.btn-link {
    text-decoration: none;
    color: $color-positive;

    &:hover,
    &:focus {
        color: $color-positive-20;
    }
}

.btn-group {
    .btn {
        font-size: 15px;
        background-color: white;
        color: $color-positive;
        border: 2px solid $color-positive;
        border-right: none;

        &.active {
            color: white;
            background-color: $color-positive;
        }

        min-width: 150px;
    }

    .btn:first-child {
        border-radius: 4px 0 0 4px;
    }

    >.btn-check+.btn:not(:nth-child(n+3)) {
        border-radius: 4px 0 0 4px;
    }

    .btn:last-child {
        border-right: 2px solid $color-positive !important;
        border-radius: 0 4px 4px 0;
    }
}

.btn-group.compact {
    .btn {
        min-width: 0
    }
}

.dropdown-toggle {
    border: 1px solid $color-neutral--90;
    background-color: white;
    font-size: 14px;
    color: black;

    &:hover,
    &:focus {
        border: 1px solid $color-neutral--90;
        background-color: white;
        color: black;
    }

    &::after {
        float: right;
        color: $color-positive;
        margin-top: 8px;
    }
}

.dropdown-toggle {
    width: 100%;
    text-align: left;
    min-height: 38px;
}

.dropdown-menu.show {
    width: 100%;
}

.query-dropdown {
    position: relative;
    flex: 1;

    select {
        /*
        padding-right: 48px;
        */
        width: 100%;
    }

    .icon {
        display: none;
        /*
        pointer-events: none;
        position: absolute;
        right: 4px;
        top: 8px;
        font-size: 24px;
        color: $color-positive;
        */
    }
}

.calendar-select-group {
    .query-dropdown {
        min-width: 295px;
        width: auto;
    }
}

h1,
h2,
.modal-title {
    font: normal normal bold 30px/45px Lexia;
    letter-spacing: 0px;
    color: $color-positive;
    margin-bottom: 0;
}

h3 {
    font: normal normal bold 25px/35px Lexia;
    letter-spacing: 0px;
    color: $color-positive;
    margin-bottom: 0;
}

.modal-header {
    border-bottom: 0;
}

.event-list {
    .list-group-item {
        background-color: transparent !important;
        border: 0;
        margin-bottom: 16px;

        .list-group-item-event {
            background-color: $li-event-background;
            padding: 30px;
        }

        .list-group-item-tasks {
            border: 2px solid $li-event-background;

            .list-group-item-task {
                margin: 20px 32px;
                background-color: white;
                text-align: left;
                font: normal normal bold 15px/23px Lexia;
                letter-spacing: 0px;
                color: $li-event-dark;

                .list-group-item-task-icon {
                    margin-right: 10px;
                    border: 2px solid $link-color;
                    width: 32px;
                    height: 32px;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .icon {
                        color: $link-color;
                        font-size: 24px;
                        font-weight: 200;
                    }
                }

                &:hover {
                    .icon {
                        padding-left: 4px;
                        transition: 400ms;
                        opacity: .6 !important;
                    }
                }
            }
        }
    }
}

h5 {
    font: normal normal bold 18px/30px Roboto;
    color: black;
    margin-bottom: 0;
}

select {
    font-size: 14px !important;
    min-height: 38px;
    padding-top: 8px;
    padding-left: 8px !important;
}

.spinner-border {
    color: $color-positive--20;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: $color-negative--20 !important;
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border: 1px solid $light-border-color;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    border-color: $light-border-color !important;
}

.was-validated .query-dropdown>.icon {
    display: none;
}

.event-view .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium {
    color: $color-positive;
}

.rbt-token {
    font-size: 12px;
    color: white !important;
    background-color: $color-positive !important;
}

label.form-label {
    font-size: 12px;
    font-weight: bold;
}

.bulletins-nav-dropdown-container {
    .dropdown-toggle {
        display: flex;
        border: none;
        height: 0;
        padding: 0;

        &::after {
            content: none;
        }
    }
}

.logged-in-user-container {
    margin-right: 10px;

    .logged-in-user-notifications-n {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        background-color: $color-negative--50;
        border-radius: 50%;
        color: white;
        font: normal normal bold 10px/30px Roboto;
        position: absolute;
        margin-left: 22px;
        top: 0;
    }

    .logged-in-user-notifications-urgent {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        position: absolute;
        margin-left: 22px;
        bottom: 0;
    }
}

.left-menu,
.left-panel {
    transition: left 300ms;
}

.left-menu {
    z-index: 10;

    .spl-link {
        margin-bottom: 12px;
        margin-top: 2px;
        color: white;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .spl-link-text {
            text-decoration: underline;
            font-size: 18px;
            margin-right: 8px;
            margin-left: 6px;
            font-weight: 500;
        }
    }
}

.menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    z-index: 9;
    display: none;
    transition: opacity 300ms;
}

@mixin maximizeWidth() {
    .container {
        max-width: 100% !important;
    }
}

.main-wrapper-0.maximize-width {
    @include maximizeWidth()
}

@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
    @include maximizeWidth()
}

@mixin maximizeContent() {
    .left-menu {
        left: -300px;
    }

    .left-menu.open {
        left: 0;
    }

    .menu-overlay {
        display: block;
        opacity: 0;
        pointer-events: none;
    }

    .menu-overlay.open {
        opacity: 1;
        pointer-events: all;
    }

    .container {
        max-width: 100% !important;
    }
}

.main-wrapper-0.maximize-content {
    .navbar-spacer {
        display: none !important;
    }

    @include maximizeContent()
}

@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
    @include maximizeContent()
}

.input-group-text {
    background-color: white;
    padding-right: 0px;

    .icon {
        font-size: 24px;
        color: $color-positive;
    }

    // Default bootstrap padding

    &.bs {
        padding: 0.375rem 0.75rem;
    }

    // Will not function in order browsers
    &:has(>.form-check-input.bs) {
        padding: 0.375rem 0.75rem;
    }

}

select {
    font-size: 1rem !important;
}

.login-layout {
    max-width: map-get($grid-breakpoints, 'sm');
}

.btn-group {
    .btn-primary {
        transition: none;

        &:hover {
            color: white;
            background-color: $color-positive-20;
        }
    }
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: $color-positive;
}

.in-out {
    display: flex;
    min-width: 200px;
    height: 36px;

    .in,
    .out {
        font-weight: normal;
        font-size: 15px;
        border-radius: 2px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &>span {
            margin-left: 6px;
        }
    }

    .in {
        margin-right: 4px;
        border: 1px solid $color-positive--20;
        color: $color-positive--20;

        &:hover {
            border: 1px solid $color-positive--10;
            color: $color-positive--10;
        }

        &.selected {
            background-color: $color-positive--20 !important;
            color: white;
        }

        &.locked {
            background-color: $color-positive--40 !important;
            color: white;
        }
    }

    .out {
        margin-left: 4px;
        border: 1px solid $color-negative--20;
        color: $color-negative--20;

        &:hover {
            border: 1px solid $color-negative--10;
            color: $color-negative--10;
        }

        &.selected {
            background-color: $color-negative--20 !important;
            color: white;
        }

        &.locked {
            background-color: $color-negative--40 !important;
            color: white;
        }
    }
}

.btn-gray {
    color: black;
    background-color: $color-neutral--90;

    &:hover {
        background-color: $color-neutral--70;
    }

    &.disabled {
        color: gray;
        background-color: $color-neutral--90;
        border-width: 0;
    }
}

.modal-participant-tag {
    border-radius: 4px;
    font-size: 12px;
    padding: 3px 6px;
    margin: 3px;
    cursor: pointer;

    &.in {
        color: $color-positive;
        border: 1px solid $color-positive;

        &:hover {
            color: white;
            background-color: $color-positive;
        }
    }

    &.out {
        color: $color-negative--20;
        border: 1px solid $color-negative--20;

        &:hover {
            color: white;
            background-color: $color-negative--20;
        }
    }
}

.list-group-item.active {
    background-color: $color-positive;
    border-color: $color-positive;
}

.logged-in-user-container {
    .bulletins-nav-dropdown-container {
        .dropdown-menu-end.show {
            display: table;
        }
    }
}

.participant-tag {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: absolute;
    right: 0;
    top: 0;
    margin: 5px;

    &.in {
        background-color: $color-positive--20;
    }

    &.out {
        background-color: $color-negative--20;
    }

    &>div {
        font-size: 14px;
        font-weight: 600;
    }
}

.participant-tag-spinner {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 7px;
}

.main-wrapper-1 {
    display: flex;
    flex: 1;
    flex-flow: column;
    overflow-x: auto
}

.calendar-layout {
    .main-wrapper-2 {
        margin-left: 0 !important;
        padding-left: 0 !important;
    }

    .main-wrapper-3 {
        padding-left: 0 !important;

        @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
            padding-right: 0 !important;
        }
    }
}

.calendar-top {
    margin-left: 8.3333333333% !important;
}

.calendar-container-month {
    padding-left: 8.3333333333% !important
}

.weeklyButton {
    background-color: transparent !important;
    color: black !important;
    border-radius: 50%;
    border-color: transparent !important;

    &::after {
        border-color: transparent !important;
    }

    &.selected {
        background-color: $color-neutral--20 !important;
    }
}

.pointer {
    cursor: pointer;
    pointer-events: all;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal {
    z-index: 2000;
}

.h-0 {
    height: 0;
}

.left-menu {
    background-color: $color-positive;
    position: fixed;
    height: 100%;

    &.alt {
        background-color: $color-positive--20;
    }

    >.left-menu-top {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 60px;
        margin-bottom: 50px;
        flex-flow: column;

        >.left-menu-img-container {
            margin-bottom: 20px;

            >* {
                background-color: rgba(0, 0, 0, .1);
                border-radius: 50%;
            }
        }

        >.left-menu-username {
            font: normal normal bold 20px/26px Roboto;
        }

        >.left-menu-role {
            width: 80%;
            font: normal normal normal 16px/21px Roboto;
        }
    }

    .navbar-nav {
        flex-flow: column;
        width: 100%;
    }

    .icon {
        font-size: 24px;
        margin-right: 20px;
    }

    .nav-link {
        width: 100%;
        font-variant: all-petite-caps;
        font-weight: 500;
        padding: 10px;
        padding-left: 30px !important;
        color: $color-positive !important;
        transition: none;
        border-left: 9px solid transparent;
        display: flex;
        align-items: center;
        font: normal normal bold 16px/24px Lexia;
        color: white !important;
        text-transform: uppercase;
        span {
            padding-right: 10px;            
        }

        &.active {
            background-color: $color-positive-20;
            border-left: 9px solid $color-negative-10;
        }
    }
}

#logged-in-user {
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
    color: black;
    border: none;

    &::after {
        margin-left: 14px;
        color: $color-positive;
        margin-top: 10px;
    }
}

.top-menu,
.top-menu-spacer {
    min-height: 75px;
}

.logged-in-user-container {
    display: flex;
}

.logged-in-user-notifications {
    display: flex;
    align-items: center;
    margin-right: 14px;
    font-size: 34px;
    color: $color-neutral--90;
}

h6,
.dropdown-header {
    text-transform: uppercase;
    color: $color-neutral;
    font: normal normal normal 15px/30px Roboto;
}

.plus-button {
    background-color: $link-color;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.plus-button-sm {
        width: 20px;
        height: 20px;
        padding: 0;
    }

    >div {
        font-size: 24px;
    }
}

.gotoregister {
    float: right;
}

.search1 {
    width: 300px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.card {
    margin-bottom: 20px;
}

.modal-footer {
    border: none;
}

.modal-header,
.modal-footer,
.modal-body {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-bottom: 1.6rem;
}

.modal-header {
    .btn-close {
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

.modal-title {
    margin-top: 20px;
}

.modal-body {
    padding-top: 0;
}

.modal-backdrop {
    &.transparent {
        background-color: transparent;
    }
}

.confirm-modal {

    &.default,
    &.info {
        .confirm-modal-title {
            .icon {
                color: $color-positive;
            }
        }
    }

    &.danger {
        .confirm-modal-title {
            .icon {
                color: $color-negative--20;
            }
        }
    }

    .modal-header {
        padding-bottom: 0;

        .btn-close {
            z-index: 2000;
        }
    }

    .confirm-modal-title {
        display: flex;
        align-items: center;
        flex-flow: column;

        .icon {
            font-size: 74px;
        }

        .confirm-modal-title-text {
            text-align: center;
            font-size: 30px;
            font-weight: bold;
        }
    }

    .confirm-modal-body {
        text-align: center;
        color: $color-neutral;
    }

    .modal-footer {
        border: 0;
        justify-content: center;
        padding-bottom: 0px;

        .cancel {
            display: flex;
            align-items: center;
        }
    }
}

.person-register {
    position: relative;
    min-height: 100vh;
}

select.form-select {
    padding-left: 0.75rem !important;
    -moz-padding-start: 0.5rem !important;
}

.query-dropdown.with-label {
    select {
        padding-top: 1.3rem !important;
        padding-bottom: 0.2rem !important;
    }

    label {
        position: absolute;
        padding-left: 0.8rem !important;
        padding-top: 0.6rem !important;
        color: $color-neutral--30;
        font: normal normal normal 10px Roboto;
    }
}

.with-label {
    position: relative;

    .label {
        left: 0;
        position: absolute;
        padding-left: 0.8rem !important;
        padding-top: 2.4rem !important;
        color: $color-neutral--30;
        font: normal normal normal 10px Roboto;
    }

    input,
    textarea {
        padding-top: 1.3rem !important;
        padding-bottom: 0.2rem !important;
    }
}

.form-modal-dynamic-tabs {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.markdown {
    strong {
        font-weight: bold
    }

    h6 {
        font-weight: bold
    }
}

.participant {
    &:first-child {
        margin-left: 0px;
    }

    &.no-img {
        background-color: white;
        color: black;

        .icon {
            font-size: 24px;
        }
    }

    &.with-img {
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
    }
}

.member-pool-list {
    .rbt-input-multi {
        height: 100% !important;
        border-left: 0;
    }

    .rbt-input-multi.focus {
        border-color: #ced4da !important;
    }
}

.help-navigation-panel {
    a {
        display: block;
        text-decoration: none;
    }
}

.markdown-view {
    [id]::before {
        content: '';
        display: block;
        height: 80px;
        margin: -80px 0 0;
    }

    img {
        max-width: 100%;
    }
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
}

strong {
    font-weight: bold;
}

@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {
    .help-navigation-panel-content {
        overflow: auto;
        bottom: 0;
        top: 0;
        padding-top: 200px;
        position: fixed;

        a.active {
            font-weight: bold;
        }
    }
}

h4 {
    font-weight: normal;
}

/*
    TODO: Following definitions need refactoring.
*/
.main-bar-chart-container {

    .recharts-text[type=category] {
        font-size: 14px;
    }

    .recharts-legend-item-text {
        font-size: 14px;
    }

    .recharts-cartesian-axis-tick-value {
        font-size: 14px;
    }
}

.main-radar-chart-container {
    &.is-fetching.category-set>.recharts-wrapper>.recharts-surface {
        opacity: .6;
    }

    .recharts-legend-wrapper {
        pointer-events: none;
    }

    .recharts-text[type=category] {
        font-size: 14px;
    }

    .recharts-legend-item-text {
        font-size: 14px;
    }

    .recharts-polar-grid-angle {
        line {
            display: none;
        }
    }

    .recharts-wrapper>.recharts-surface {
        margin-top: 20px;
    }
}

.kpi-radar-chart-back {
    width: 100%;
}

.with-categories.main-radar-chart-container {
    .recharts-text.recharts-polar-angle-axis-tick-value {
        cursor: pointer;
        fill: $link-color;
        text-decoration: underline;
    }

    .recharts-text.recharts-polar-angle-axis-tick-value:hover {
        fill: $link-hover-color;
    }
}

.physical-properties-chart {
    &:not(.modal) .recharts-cartesian-axis-tick-value {
        font-size: 14px;
    }

    &.modal .recharts-legend-item {
        margin-left: 14px;
        margin-right: 14px;
    }

    &.modal .recharts-legend-wrapper>div {
        font-size: 18px !important;
        font-weight: bold;
        margin-top: -10px;
    }

    .recharts-label {
        font-size: 14px;
    }
}

.physical-properties-chart-header {
    .MuiInputBase-root {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }

    .MuiInputBase-input {
        padding-top: 10px;
        padding-bottom: 8px;
        font-size: .9em;
    }

    .MuiInputLabel-root {
        font-size: .9em;
    }
}

.event-picker {
    :first-child.disabled {
        background-color: $color-neutral--70;
        color: $color-neutral
    }

    .dropdown-menu.show {
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        border: 0;
        padding: 0;
        min-width: 260px;
        max-width: 640px;

        .dropdown-item-text {
            padding: 0;
        }

        >.dropdown-item-text {
            max-height: 400px;
            display: flex;
            flex-flow: column;

            >.list-group {
                border-radius: 0;
                overflow-y: scroll;
            }
        }

        .list-group-item {
            border-radius: 0;
            border-top: 0;
            cursor: pointer;
        }
    }

    &.no-radius-right {
        .dropdown-toggle {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &.no-radius.left {
        .dropdown-toggle {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .dropdown-toggle {
        &::after {
            margin-top: -20px;
        }

        cursor: pointer;
        padding: 0.375rem 0.75rem;
        border-radius: 0.375rem;
        border: 1px solid #ced4da;
        min-width: 100px;

        &:hover {
            border-color: black;
        }

        font-size: 1rem;
    }
}

.charts-group-table,
.latest-delta-table {
    thead {
        background-color: white;
        position: sticky;
        top: 0px;
        margin: 0;
    }
}


.charts-group-table {

    margin-bottom: 0;

    .delta-row {
        font-size: 1em;
    }

    .dark {
        td {
            --bs-table-accent-bg: var(--bs-table-striped-bg);
        }
    }

    .highlight {
        td {
            --bs-table-accent-bg: lightyellow
        }
    }

    th {
        vertical-align: top;
    }
}

table.table.table-striped tr.excluded td {
    background-color: darkgray;
}

.pie-chart-legend {
    .recharts-legend-item {
        margin-right: 25px !important;
        font-size: 14px;

        svg {
            margin-bottom: 2px;
        }
    }
}

.coach-charts {
    .recharts-cartesian-axis-tick-value {
        font-size: 14px;
    }

    .recharts-pie-labels,
    .recharts-legend-wrapper {
        pointer-events: none;
    }

    .head {
        border-bottom: 1px solid #1D4F91;
        color: #1D4F91;
        padding: 5px 10px;
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 20px;

    }

    .recharts-legend-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.physical .recharts-legend-wrapper>div {
        max-width: 140px;
    }

    .recharts-legend-item-text {
        font-size: 12px;
    }
}

.sticky-column {
    /* Media query to prevent undisplayable content on small screens */
    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        position: sticky;
        left: 0;
        z-index: 10;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border-left: 1px solid black;
            border-right: 1px solid black;
        }
    }
}

.sticky-column-no-border {
    /* Media query to prevent undisplayable content on small screens */
    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        position: sticky;
        left: 0;
        z-index: 10;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.dev-only-tint {
    background-color: rgba(255, 0, 0, 0.225);
}

.recharts-tooltip-wrapper {
    z-index: 1000;
}

.jsPanel-title {
    font-variant: inherit !important;
}

.jsPanel-titlebar {
    height: 32px !important;
}

.jsPanel-hdr {
    background-color: $color-positive !important;
}

.calendar-legend {
    .calendar-legend-item {
        min-height: 34px;
        margin-top: 3px;
        margin-bottom: 3px;
        align-items: center;
        padding-right: 12px;
        user-select: none;
        min-width: 260px;
        width: 100%;

        &:not(.calendar-legend-item-selected) {
            span {
                padding-right: 10px;
            }
        }
    }

    .calendar-legend-item-selected {
        border-radius: 20px;
        padding-left: 10px;
        outline: 1px solid #1D4F91;
        color: #1D4F91;
        font-weight: 500;
        background-color: #FFFFFF;
        margin-left: -10px;
        transition: background-color 50ms ease-in-out;
        margin-right: 10px;
    }
}

// Mimics recharts tooltip styling; for use in custom Tooltip render
.custom-tooltip {
    margin: 0px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    white-space: nowrap;
}

// Quick thinn custom scroll bar for DigitalClock in @mui/x-date-pickers
.MuiMultiSectionDigitalClock-root {

    /* width */
    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.element-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.6;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 2
}

.table-top-header,
.table-top-header th {
    background-color: $color-positive !important;
    color: white;
    padding: 4px;
    text-align: center;
    font-weight: 500;
}

.table-vertical-align-top thead th {
    vertical-align: top;
}

.table-tr-highlight>td {
    background-color: $color-positive--20 !important;
    color: white !important;
}

.table-top-legend {
    margin-bottom: 8px;
    display: flex;
    justify-content: center;

    .table-top-legend-item {
        padding: 6px 10px;
        border-radius: 10px;
        cursor: pointer;

        .table-top-legend-icon {
            width: 18px;
            height: 18px;
            border-radius: 50%;
        }

        .table-top-legend-label {
            margin-left: 4px;
            align-items: center;
        }

        display: flex;
        align-items: center;
    }
}

.table-top-header {
    border-radius: 10px 10px 0 0;
    padding: 6px;
}

.exercise-chart-notype {
    .recharts-default-legend {
        visibility: hidden;
    }
}

.bar-badge {
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .3);
    font-size: .8em;
}

.sick-badge {
    background-color: rgba(255, 192, 0, .5);
}

.injury-badge {
    background-color: rgba(255, 0, 0, .6);
}

.video-dropdown-toggle:after {
    display: none;
    background-color: rgba(255, 0, 0, .6);
}

.highlighted-video-row {
    $backgroundColor: $color-neutral--80;
    background: $backgroundColor;

    td { background: $backgroundColor; }
}

.video-sizemeter-and-button-row {
    display: flex;
    justify-content: space-between;
    // Adds spacing when in column-mode
    gap: 20px;

    $video-create-button-media-breakpoint-px: 650px;

    // Lift up by a few pixel to center vertically
    div:has(#video-sizemeter-container) {
        position: relative;
        top: -10px
    }

    @media screen and (max-width: $video-create-button-media-breakpoint-px) {
        flex-direction: column;
    }

    div:has(button) {
        display: inline-block;
        @media screen and (max-width: $video-create-button-media-breakpoint-px) {
            margin-top: 0.8rem;
            button {
                width: 100%
            }
        }
    }
}

.videolibrary-date-filter-row {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    $breakpoint: 650px;

    @media screen and (max-width: $breakpoint) {
        flex-direction: column;
        column-gap: unset;
    }
}

// Alternate striped table style
.target-level-table {
    thead {
        background-color: $color-target-table-header;
    }

    >tbody>tr:nth-of-type(even)>* {
        background-color: $color-target-table-row-even;
        color: $color-target-table-row-text;
    }

    >tbody>tr:nth-of-type(odd)>* {
        background-color: $color-target-table-row-odd;
        color: $color-target-table-row-text;
    }

    > :not(caption)>*>* {
        color: $color-target-table-header-text;
        background-color: $color-target-table-header;
    }

    >tbody>tr>td:nth-of-type(1) {
        background-color: $color-target-table-initial-col;
        color: $color-target-table-initial-col-text;
    }

    >tbody>tr>th:nth-of-type(1) {
        background-color: $color-target-table-initial-col;
        color: $color-target-table-initial-col-text;
    }
}

.custom-borderless-table {
    border-color: $color-custom-borderless-table-header;

    thead {
        border-width: 2px;
    }

    tbody {
        border-width: 2px;
    }

    th {
        background-color: $color-custom-borderless-table-header;
        color: white;
        text-align: center;
    }

    td {
        text-align: center;
        border-bottom-width: 0;
    }
}

.left-align {
    td {
        text-align: left;
    }

    th {
        text-align: left;
    }
}

.first-col-left-align {
    td:nth-of-type(1) {
        text-align: left;
    }

    th:nth-of-type(1) {
        text-align: left;
    }
}

.MuiPickersPopper-root {
    z-index: 2000 !important;
}

.normal-btn {
    width: 110px
}

.textarea-noresize {
    resize: none;
}

.pre {
    font-family: "Roboto";
    font-size: 1em;
    white-space: pre-wrap;
}

.period-plan-min-height {
    min-height: 60px;
}

.period-plan-min-width {
    min-width: 120px
}

.period-plan-subject-col {
    background-color: $li-event-background !important;
}

.period-plan-topic-row {
    background-color: $li-event-background !important;
}

.period-plan-textarea {
    overflow: hidden;
    resize: none;
    width: 100%;
    height: 100%;
    min-height: 110px;
    box-sizing: border-box;
}

.period-plan-textarea-resize {
    overflow: hidden;
    resize: vertical;
    width: 100%;
    height: 100%;
    min-height: 110px !important;
    max-height: 500px !important;
    box-sizing: border-box;
}

.tr-period-plan {
    min-height: 110px;
    max-height: 500px;
    height: 100px
}

.td-period-plan {
    height: 100%;
}

.alt-actions.dropdown {
    .dropdown-menu.show {
        width: auto;
        min-width: 100%;
    }
    .dropdown-toggle {
        margin-left: 1px;
        background-color: $color-positive;
        color: white;
        border: 1px solid $color-positive;
        border-radius: 4px;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        cursor: pointer;
        transition: none;
        &:hover {
            color: white;
        }
        border-radius: 0 2px 2px 0;
    }
    .dropdown-toggle::after {
        color: white !important;
        padding-bottom: 5px;
    }
}

.club-support-table {
    th {
        vertical-align: bottom;
    }
}

.localized-input {
    border-color: $color-positive;
    background-color: $color-positive--90;
    border-width: 3px;
    box-shadow: 0 0 2px #090909;
}

.calendar-invalid {
    background-color: #ffdddd !important;
}