$color-positive-90: #03080E;
$color-positive-80: #06101D;
$color-positive-70: #09182B;
$color-positive-60: #0C203A;
$color-positive-50: #0F2849;
$color-positive-40: #112F57;
$color-positive-30: #143766;
$color-positive-20: #173F74;
$color-positive-10: #1A4783;
$color-positive: #1D4F91;
$color-positive--10: #34619C;
$color-positive--20: #4A72A7;
$color-positive--30: #6184B2;
$color-positive--40: #7795BD;
$color-positive--50: #8EA7C8;
$color-positive--60: #A5B9D3;
$color-positive--70: #BBCADE;
$color-positive--80: #D2DCE9;
$color-positive--90: #E8EDF4;

$color-negative-90: #190800;
$color-negative-80: #331000;
$color-negative-70: #4C1800;
$color-negative-60: #662000;
$color-negative-50: #7F2800;
$color-negative-40: #983000;
$color-negative-30: #B23800;
$color-negative-20: #CB4000;
$color-negative-10: #E54800;
$color-negative: #FE5000;
$color-negative--10: #FE621A;
$color-negative--20: #FE7333;
$color-negative--30: #FE854D;
$color-negative--40: #FE9666;
$color-negative--50: #FFA880;
$color-negative--60: #FFB999;
$color-negative--70: #FFCBB3;
$color-negative--80: #FFDCCC;
$color-negative--90: #FFEEE6;

$color-neutral-90: #0A0A0B;
$color-neutral-80: #141415;
$color-neutral-70: #1E1F20;
$color-neutral-60: #28292A;
$color-netural-50: #323335;
$color-neutral-40: #3B3D40;
$color-neutral-30: #45474A;
$color-neutral-20: #4F5255;
$color-neutral-10: #595C5F;
$color-neutral: #63666A;
$color-neutral--10: #737579;
$color-neutral--20: #828588;
$color-neutral--30: #929497;
$color-neutral--40: #929497;
$color-neutral--50: #A1A3A6;
$color-neutral--60: #B1B3B5;
$color-neutral--70: #C1C2C3;
$color-neutral--80: #D0D1D2;
$color-neutral--90: #E0E0E1;

$color-target-table-header: #4472c4;
$color-target-table-header-text: white;
$color-target-table-row-text: black;
$color-target-table-row-even: #cfd5ea;
$color-target-table-row-odd: #e9ebf5;
$color-target-table-initial-col: #4472c4;
$color-target-table-initial-col-text: white;

$color-custom-borderless-table-header: #0048cc;